import React from 'react';
import { Card, CardHeader, CardContent } from '../components/ui/card';
import { Mic, Camera, BookOpen, PenTool, Share2, BarChart } from 'lucide-react';

const FeaturePillars = () => {
  const pillars = [
    {
      title: "Collecting Your Insights",
      description: "AI-Powered Ways to Capture Your Expertise",
      features: [
        {
          icon: <Mic className="w-6 h-6" />,
          title: "AI Interviewer",
          description: "Smart AI interviewer that draws out your best insights in just 15 minutes",
          isActive: true
        },
        {
          icon: <Camera className="w-6 h-6" />,
          title: "Event Capture",
          description: "AI transforms your event photos and voice notes into engaging content",
          isActive: false
        },
        {
          icon: <BookOpen className="w-6 h-6" />,
          title: "Content Import",
          description: "AI analyzes your existing content (books, podcasts, articles) to extract key insights",
          isActive: false
        }
      ]
    },
    {
      title: "Creating & Publishing",
      description: "AI Creates Content Across All Your Channels",
      features: [
        {
          icon: <PenTool className="w-6 h-6" />,
          title: "Social Media Suite",
          description: "LinkedIn posts now, with X, Instagram, and more platforms coming soon",
          isActive: true
        },
        {
          icon: <Share2 className="w-6 h-6" />,
          title: "Multi-Format Content",
          description: "Text posts, image cards, video snippets, and article links optimized for each platform",
          isActive: true
        },
        {
          icon: <BarChart className="w-6 h-6" />,
          title: "Extended Content",
          description: "AI-crafted blog posts, newsletters, and email sequences that expand on your insights",
          isActive: false
        }
      ]
    },
    {
      title: "Measuring & Improving",
      description: "AI-Driven Optimization of Your Thought Leadership",
      features: [
        {
          icon: <BarChart className="w-6 h-6" />,
          title: "Smart Analytics",
          description: "AI tracks and analyzes engagement patterns across all your content",
          isActive: true
        },
        {
          icon: <PenTool className="w-6 h-6" />,
          title: "AI Content Coach",
          description: "Get AI-powered recommendations to improve engagement and reach",
          isActive: false
        },
        {
          icon: <Share2 className="w-6 h-6" />,
          title: "Audience Intelligence",
          description: "AI helps you understand and grow your professional audience",
          isActive: false
        }
      ]
    }
  ];

  return (
    <div className="w-full bg-white">
      <div className="max-w-spark mx-auto px-section-x py-section">
        <div className="text-center mb-12">
          <h2 className="text-spark-h1 text-spark-text-heading mb-4">Core Features</h2>
          <p className="text-spark-subheading text-spark-text-body">Powerful AI tools to amplify your thought leadership</p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {pillars.map((pillar, index) => (
            <div key={index} className="space-y-6">
              <div className="text-center pb-4">
                <h2 className="text-2xl font-bold mb-2 text-spark-text-heading">{pillar.title}</h2>
                <p className="text-spark-text-body transition-opacity duration-200">
                  {pillar.description}
                </p>
              </div>
              
              <div className="space-y-4">
                {pillar.features.map((feature, featureIndex) => (
                  <Card 
                    key={featureIndex} 
                    className="relative bg-white group hover:shadow-spark transition-all duration-300"
                  >
                    {!feature.isActive && (
                      <span className="absolute top-2 right-2 bg-spark-purple text-white text-xs px-2 py-1 rounded-full">
                        Coming Soon
                      </span>
                    )}
                    <CardHeader className="flex flex-row items-center space-x-4 pb-2">
                      <div className="text-spark-text-body">{feature.icon}</div>
                      <h3 className="font-semibold text-spark-text-heading">{feature.title}</h3>
                    </CardHeader>
                    <CardContent>
                      <p className="text-sm text-spark-text-body opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                        {feature.description}
                      </p>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturePillars;