import React from 'react';
import { ArrowRight, Clock, Sparkles } from 'lucide-react';
import { Button } from '../components/ui/button';

const Hero = ({ onWaitlistClick }) => {  
  return (
    <div className="relative w-full" style={{
      background: 'radial-gradient(circle, rgba(138, 43, 226, 0.3), rgba(138, 43, 226, 0.1), transparent 70%)'
    }}>
      <div className="max-w-spark mx-auto px-section-x py-section">
        <div className="flex flex-col items-center text-center space-y-8">
          <div className="flex items-center space-x-2 bg-[rgba(138,43,226,0.1)] rounded-full px-4 py-2">
            <Clock className="w-4 h-4 text-spark-purple" />
            <span className="text-sm text-spark-purple font-medium">15 minutes → month of content</span>
          </div>
          
          <h1 className="text-spark-h1 text-spark-text-heading max-w-4xl">
            Your Expertise, Amplified by AI
          </h1>
          
          <p className="text-spark-subheading text-spark-text-body max-w-3xl">
            Turn a simple conversation into a month of engaging thought leadership content across all your channels
          </p>
          
          <div className="flex flex-col sm:flex-row gap-4">
            <Button 
              onClick={onWaitlistClick}
              className="bg-spark-purple hover:bg-[#7B27CC] text-white px-4 py-3 text-[18px] rounded-spark shadow-spark inline-flex items-center"
            >
              Join the Waitlist
              <ArrowRight className="ml-2 w-4 h-4" />
            </Button>
            
            <div className="flex items-center justify-center space-x-2 text-spark-text-body">
              <Sparkles className="w-4 h-4" />
              <span>No content creation skills needed</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;