import React from 'react';

const Steps = () => {
  const steps = [
    {
      title: "Step 1: AI Interview",
      description: "Have a 15-minute phone conversation with our AI bot, where it intelligently interviews you about your thoughts and experiences."
    },
    {
      title: "Step 2: AI Processing",
      description: "The AI analyzes the conversation, extracting key insights and understanding your message."
    },
    {
      title: "Step 3: Social Media Creation",
      description: "Spark AI generates a series of engaging LinkedIn posts (and other platforms soon) based on your conversation."
    },
    {
      title: "Step 4: Notification",
      description: "You receive a text message alerting you when the posts are ready to go live."
    },
    {
      title: "Step 5: Review & Control",
      description: "You can allow the posts to go live automatically or review and provide feedback. You're always in control."
    },
    {
      title: "Step 6: Analytics & Insights",
      description: "Access detailed analytics to track engagement and reach, helping you understand how your insights are resonating with your audience."
    }
  ];

  return (
    <ol className="list-none p-0 mt-8">
      {steps.map((step, index) => (
        <li key={index} className="mb-8 text-spark-text-body">
          <strong className="text-[22px] block mb-2 text-spark-text-heading">
            {step.title}
          </strong>
          <span className="text-[18px] leading-relaxed block">
            {step.description}
          </span>
        </li>
      ))}
    </ol>
  );
};

export default Steps;