import React from 'react';
import { Button } from '../components/ui/button';
import { ArrowRight, Sparkles, Clock } from 'lucide-react';

const FinalCTA = () => {
  const scrollToWaitlist = () => {
    document.getElementById('waitlist').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="relative overflow-hidden w-full" style={{
      background: 'radial-gradient(circle, rgba(138, 43, 226, 0.3), rgba(138, 43, 226, 0.1), transparent 70%)'
    }}>
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute top-0 left-0 w-40 h-40 transform -translate-x-16 -translate-y-16">
          <div className="absolute inset-0 rotate-45 bg-white"></div>
        </div>
        <div className="absolute bottom-0 right-0 w-40 h-40 transform translate-x-16 translate-y-16">
          <div className="absolute inset-0 rotate-45 bg-white"></div>
        </div>
      </div>

      <div className="relative max-w-spark mx-auto px-section-x py-section">
        {/* Stats Bar */}
        <div className="flex justify-center space-x-12 mb-12">
          <div className="text-center">
            <div className="flex items-center justify-center mb-2">
              <Clock className="w-5 h-5 mr-2 text-spark-purple" />
              <span className="text-2xl font-bold text-spark-text-heading">15min</span>
            </div>
            <p className="text-sm text-spark-text-body">Interview Time</p>
          </div>
          <div className="text-center">
            <div className="flex items-center justify-center mb-2">
              <Sparkles className="w-5 h-5 mr-2 text-spark-purple" />
              <span className="text-2xl font-bold text-spark-text-heading">30</span>
            </div>
            <p className="text-sm text-spark-text-body">Days of Content</p>
          </div>
        </div>

        {/* Main Content */}
        <div className="text-center space-y-8">
          <h2 className="text-spark-h1 text-spark-text-heading">
            Ready to Amplify Your Thought Leadership?
          </h2>
          
          <p className="text-spark-subheading text-spark-text-body max-w-2xl mx-auto">
            Join the waitlist today and be among the first to transform your expertise into engaging content.
          </p>

          {/* CTA Button */}
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <Button 
              onClick={scrollToWaitlist}
              className="bg-spark-purple hover:bg-[#7B27CC] text-white px-8 py-3 text-[18px] rounded-spark shadow-spark inline-flex items-center"
            >
              Join the Waitlist
              <ArrowRight className="ml-2 w-4 h-4" />
            </Button>
            <span className="text-sm text-spark-text-body">
              Limited spots available for early access
            </span>
          </div>

          {/* Benefits List */}
          <div className="pt-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left max-w-3xl mx-auto">
              <div className="flex items-start">
                <div className="h-1 w-8 bg-spark-purple opacity-50 mt-3 mr-3"></div>
                <p className="text-sm text-spark-text-body">Early access to new features and platforms</p>
              </div>
              <div className="flex items-start">
                <div className="h-1 w-8 bg-spark-purple opacity-50 mt-3 mr-3"></div>
                <p className="text-sm text-spark-text-body">Priority onboarding support</p>
              </div>
              <div className="flex items-start">
                <div className="h-1 w-8 bg-spark-purple opacity-50 mt-3 mr-3"></div>
                <p className="text-sm text-spark-text-body">Special early adopter pricing</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalCTA;