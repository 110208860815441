import React from 'react';
import Footer from '../components/Footer';

function TermsOfService() {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header section */}
      <div className="bg-gray-50 py-8">
        <div className="px-8 md:px-16">
          <h1 className="text-5xl font-bold text-gray-900 mb-2">Terms of Service for SparkAI</h1>
          <p className="text-gray-600">Last Updated: September 2024</p>
        </div>
      </div>

      {/* Content section */}
      <div className="px-8 md:px-16 py-12 flex-grow">
        <div>
          {/* Update all sections to use these colors: */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">1. Introduction</h2>
            <p className="text-gray-600 text-lg leading-relaxed mb-4">
              This document constitutes the Terms of Service ("Agreement") between SparkAI, doing business as SparkAI (referred to as "we," "us," "our"), and you ("User," "you").
            </p>
            <p className="text-gray-600 text-lg">
              For inquiries, contact: <a href="mailto:support@sparkai-dev.com" className="text-blue-600 hover:text-blue-700">support@sparkai-dev.com</a>
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">2. Description of Services</h2>
            <p className="text-gray-600 text-lg mb-4">
              SparkAI offers AI-powered tools to assist users in generating social media content based on their expertise and work experience. The service includes an AI interview, content generation, and continuous improvement based on user feedback. The service begins with a free trial, after which a subscription fee applies.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">3. User Responsibilities</h2>
            <p className="text-gray-600 text-lg mb-4">
              You agree to provide content based on your knowledge and professional experience. You must not:
            </p>
            <ul className="list-disc pl-6 text-gray-600 text-lg space-y-2">
              <li>Submit inappropriate, offensive, or illegal content.</li>
              <li>Use the platform for unlawful activities or in a manner inconsistent with its intended purpose.</li>
            </ul>
            <p className="text-gray-600 text-lg">
              You are responsible for reviewing all AI-generated content before it is published. SparkAI is not liable for inaccuracies or undesired outcomes in the content generated by the AI.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">4. Content Ownership and Usage</h2>
            <p className="text-gray-600 text-lg mb-4">
              While you retain ownership of your original content, by using SparkAI, you grant us a non-exclusive, perpetual, and royalty-free license to use your generated content for analysis to improve our services. SparkAI will not sell or distribute your content without your permission.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">5. Subscription and Billing</h2>
            <p className="text-gray-600 text-lg mb-4">
              The free trial provides access to basic features of SparkAI. After the trial period, you must subscribe to a paid plan to continue using the services. Billing details will be provided upon subscription, and it is your responsibility to ensure timely payments to avoid service disruption.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">6. Termination</h2>
            <p className="text-gray-600 text-lg mb-4">
              SparkAI reserves the right to terminate your account if you:
            </p>
            <ul className="list-disc pl-6 text-gray-600 text-lg space-y-2">
              <li>Engage in misuse of the platform.</li>
              <li>Provide inappropriate or illegal content.</li>
              <li>Use the service in a manner that violates this Agreement.</li>
            </ul>
            <p className="text-gray-600 text-lg">
              Termination may occur without prior notice in cases of severe violations.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">7. Disclaimers and Limitations of Liability</h2>
            <p className="text-gray-600 text-lg mb-4">
              SparkAI provides its services on an "as is" and "as available" basis. We do not guarantee that the AI-generated content will be free from errors or align perfectly with your expectations.
            </p>
            <p className="text-gray-600 text-lg">
              SparkAI is not responsible for any losses or damages resulting from your use of the service, including but not limited to content inaccuracies or technical issues. You are responsible for reviewing and approving all generated content before publication.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">8. Governing Law</h2>
            <p className="text-gray-600 text-lg mb-4">
              This Agreement is governed by the laws of the State of California, USA, without regard to its conflict of law principles.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">9. Modifications to the Terms</h2>
            <p className="text-gray-600 text-lg mb-4">
              We reserve the right to update or modify these Terms of Service at any time. Users will be notified of any significant changes, and continued use of the service constitutes acceptance of the updated Terms.
            </p>
          </section>
        </div>
      </div>

      {/* Updated Footer to match product page */}
      <footer className="bg-gray-100 py-5 mt-10">
        <div className="px-8 md:px-16 text-center">
          <p className="text-spark-text-body">
            <a href="terms-of-service.html" className="text-spark-purple hover:text-[#7B27CC] mr-4">
              Terms of Service
            </a>
            <a href="privacy-policy.html" className="text-spark-purple hover:text-[#7B27CC]">
              Privacy Policy
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default TermsOfService;
