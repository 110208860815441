import React from 'react';
import { Card, CardContent } from '../components/ui/card';
import { TrendingUp, Clock, Users, BarChart } from 'lucide-react';

const ResultsSection = () => {
  const metrics = [
    {
      icon: <Clock className="w-8 h-8 text-spark-purple" />,
      stat: "95%",
      label: "Time Saved vs Manual Content Creation"
    },
    {
      icon: <TrendingUp className="w-8 h-8 text-spark-purple" />,
      stat: "3.4x",
      label: "Average Engagement Increase"
    },
    {
      icon: <Users className="w-8 h-8 text-spark-purple" />,
      stat: "2.8x",
      label: "Follower Growth Rate"
    },
    {
      icon: <BarChart className="w-8 h-8 text-spark-purple" />,
      stat: "87%",
      label: "Users Report More Consistent Presence"
    }
  ];

  const testimonials = [
    {
      quote: "I went from posting once a month to having quality content every other day. My network engagement has exploded.",
      author: "Sarah Chen",
      role: "Tech Executive",
      results: "4.2x engagement increase"
    },
    {
      quote: "The AI interviewer is brilliant. It asks the right questions and turns my rambling thoughts into coherent content.",
      author: "Marcus Rodriguez",
      role: "Startup Founder",
      results: "2,500+ new followers in 3 months"
    },
    {
      quote: "Finally, I can share my expertise consistently without spending hours writing and editing posts.",
      author: "Dr. Lisa Thompson",
      role: "Healthcare Consultant",
      results: "89% time saved on content creation"
    }
  ];

  return (
    <div className="w-full bg-white">
      <div className="max-w-spark mx-auto px-section-x py-section">
        <div className="text-center mb-12">
          <h2 className="text-spark-h1 text-spark-text-heading mb-4">Proven Results</h2>
          <p className="text-spark-subheading text-spark-text-body">Our beta users are seeing dramatic improvements in their online presence</p>
        </div>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
          {metrics.map((metric, index) => (
            <Card key={index} className="text-center p-6 bg-white shadow-spark hover:shadow-md transition-shadow">
              <CardContent className="space-y-4">
                {metric.icon}
                <div className="text-3xl font-bold text-spark-purple">{metric.stat}</div>
                <div className="text-sm text-spark-text-body">{metric.label}</div>
              </CardContent>
            </Card>
          ))}
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <Card key={index} className="relative bg-white shadow-spark hover:shadow-md transition-shadow">
              <CardContent className="p-6 space-y-4">
                <div className="text-4xl text-spark-purple mb-4">"</div>
                <p className="text-spark-text-body italic mb-4">{testimonial.quote}</p>
                <div className="border-t pt-4">
                  <div className="font-semibold text-spark-text-heading">{testimonial.author}</div>
                  <div className="text-sm text-spark-text-body">{testimonial.role}</div>
                  <div className="text-sm text-spark-purple mt-2 font-medium">
                    {testimonial.results}
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResultsSection;