import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navigation = () => {
  const location = useLocation();
  
  return (
    <nav className="w-full bg-white shadow-spark">
      <div className="max-w-spark mx-auto px-content py-5">
        <div className="flex justify-between items-center">
          {/* Logo/Brand */}
          <Link to="/" className="text-2xl font-bold text-spark-text-heading">
            Spark AI
          </Link>

          {/* Navigation Links */}
          <ul className="flex space-x-8">
            <li>
              <Link 
                to="/" 
                className={`text-base ${
                  location.pathname === '/' 
                    ? 'text-spark-purple font-medium' 
                    : 'text-spark-text-body hover:text-spark-purple'
                }`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link 
                to="/product" 
                className={`text-base ${
                  location.pathname === '/product' 
                    ? 'text-spark-purple font-medium' 
                    : 'text-spark-text-body hover:text-spark-purple'
                }`}
              >
                Product
              </Link>
            </li>
            <li>
              <Link 
                to="/resources" 
                className={`text-base ${
                  location.pathname.startsWith('/resources') 
                    ? 'text-spark-purple font-medium' 
                    : 'text-spark-text-body hover:text-spark-purple'
                }`}
              >
                Resources
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;