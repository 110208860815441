import React, { useState } from 'react';
import HomeWaitlistForm from '../components/HomeWaitlistForm';
import Steps from '../components/Steps';
import HomeFAQ from '../components/HomeFAQ';
import phoneImg from '../assets/phone.png';
import linkedinPostImg from '../assets/linkedin-post.png';

const HomePage = () => {
  const handleFormSubmit = async (formId, formData) => {
    try {
      const response = await fetch('https://zsoctoqpc9.execute-api.us-east-1.amazonaws.com/prod/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      return response.ok ? 'Success! Your request has been submitted.' : 'Submission failed';
    } catch (error) {
      return 'There was an error. Please try again.';
    }
  };

  return (
    <div className="min-h-screen" style={{
      background: 'radial-gradient(circle, rgba(138, 43, 226, 0.3), rgba(138, 43, 226, 0.1), transparent 70%)'
    }}>
      {/* Main Hero Section */}
      <section className="px-8 md:px-16 py-section">
        <div className="flex flex-col md:flex-row items-center gap-8">
          {/* Text content side */}
          <div className="flex-1 space-y-6">
            <h1 className="text-spark-h1 text-spark-text-heading">
              Turn 15 Minutes Into a Month of Thought Leadership
            </h1>
            <p className="text-spark-subheading text-spark-text-body">
              As a busy professional, finding time for your online presence is tough. 
              In just 15 minutes, Spark AI creates, posts, tracks, and improves your 
              content—keeping you visible without the hassle.
            </p>
            <HomeWaitlistForm 
              id="top-form"
              onSubmit={handleFormSubmit}
            />
          </div>

          {/* Images side */}
          <div className="flex-1 min-w-[250px] relative">
            <img 
              src={phoneImg} 
              alt="Phone with AI voice bot interaction"
              className="max-w-[80%] h-auto"
            />
            <img 
              src={linkedinPostImg} 
              alt="LinkedIn post overlay"
              className="absolute top-1/2 -translate-y-1/2 right-0 max-w-[60%] z-10"
            />
          </div>
        </div>
      </section>

      {/* Who is Spark AI For Section */}
      <section className="bg-white px-8 md:px-16 py-section">
        <div>
          <h2 className="text-spark-h1 text-spark-text-heading text-center">
            Who is Spark AI For?
          </h2>
          <p className="text-spark-body-lg text-spark-text-body text-center mx-auto mt-5">
            Spark AI is designed for executives, startup founders, professionals, consultants, 
            and anyone with industry experience who has valuable insights and knowledge to share. 
            If you're looking to grow your influence and build awareness in your field or with 
            potential customers, Spark AI is for you.
          </p>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="bg-white px-8 md:px-16 py-section">
        <div>
          <h2 className="text-spark-h1 text-spark-text-heading text-center">
            How Does It Work?
          </h2>
          <Steps />
        </div>
      </section>

      {/* FAQ Section */}
      <section className="bg-white px-8 md:px-16 py-section">
        <div>
          <h2 className="text-spark-h1 text-spark-text-heading text-center">
            Frequently Asked Questions
          </h2>
          <HomeFAQ />
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="px-8 md:px-16 py-section">
        <div className="text-center">
          <h2 className="text-spark-h1 text-spark-text-heading">Join the Waitlist</h2>
          <HomeWaitlistForm 
            id="bottom-form"
            onSubmit={handleFormSubmit}
          />
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-100 py-5 mt-10">
        <div className="text-center">
          <p className="text-spark-text-body">
            <a href="terms-of-service.html" className="text-spark-purple hover:text-[#7B27CC] mr-4">
              Terms of Service
            </a>
            <a href="privacy-policy.html" className="text-spark-purple hover:text-[#7B27CC]">
              Privacy Policy
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;