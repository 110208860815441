import React from 'react'
import Hero from '../components/Hero'
import FeaturePillars from '../components/FeaturePillars'
import HowItWorks from '../components/HowItWorks'
import ResultsSection from '../components/ResultsSection'
import PricingSection from '../components/PricingSection'
import FAQSection from '../components/FAQSection'
import WaitlistForm from '../components/WaitlistForm'
import FinalCTA from '../components/FinalCTA'

function ProductPage() {
  const scrollToWaitlist = () => {
    document.getElementById('waitlist').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen bg-white">
      <Hero onWaitlistClick={scrollToWaitlist} />
      <main className="w-full flex flex-col">
        <section id="how-it-works" className="w-full">
          <div className="px-8 md:px-16">
            <HowItWorks />
          </div>
        </section>

        <section id="features" className="w-full">
          <div className="px-8 md:px-16">
            <FeaturePillars />
          </div>
        </section>

        <section id="results" className="w-full">
          <div className="px-8 md:px-16">
            <ResultsSection />
          </div>
        </section>

        <section id="pricing" className="w-full">
          <div className="px-8 md:px-16">
            <PricingSection onWaitlistClick={scrollToWaitlist} />
          </div>
        </section>

        <section id="faq" className="w-full">
          <div className="px-8 md:px-16">
            <FAQSection />
          </div>
        </section>

        <section id="cta" className="w-full">
          <div className="px-8 md:px-16">
            <FinalCTA onWaitlistClick={scrollToWaitlist} />
          </div>
        </section>

        <section className="w-full">
          <div className="px-8 md:px-16">
            <WaitlistForm />
          </div>
        </section>
      </main>

      <footer className="bg-gray-100 py-5 mt-10">
        <div className="px-8 md:px-16 text-center">
          <p className="text-spark-text-body">
            <a href="terms-of-service.html" className="text-spark-purple hover:text-[#7B27CC] mr-4">
              Terms of Service
            </a>
            <a href="privacy-policy.html" className="text-spark-purple hover:text-[#7B27CC]">
              Privacy Policy
            </a>
          </p>
        </div>
      </footer>
    </div>
  )
}

export default ProductPage;
