import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';

const FAQSection = () => {
  const [openItem, setOpenItem] = useState(null);
  
  const faqItems = [
    {
      question: "How does the AI maintain my voice?",
      answer: "Our AI analyzes your speaking patterns, word choice, and expertise during the interview process to create content that authentically reflects your voice and perspective."
    },
    {
      question: "Which social platforms are supported?",
      answer: "We currently support LinkedIn, with X, Instagram, and other platforms coming soon. Each post is optimized for the specific platform it's published on."
    },
    {
      question: "How can I trust the quality of the content?",
      answer: "You have full control over all content before it's published. Our AI generates posts based on your actual insights, and you can review and adjust anything before it goes live."
    },
    {
      question: "Can my team collaborate on content?",
      answer: "Yes, our Enterprise plan includes team collaboration features, allowing multiple team members to contribute insights while maintaining a consistent brand voice."
    },
    {
      question: "How is my conversation data protected?",
      answer: "We use enterprise-grade encryption and security measures to protect your data. Your conversations and content are private and only accessible to you and your authorized team members."
    }
  ];

  return (
    <div className="w-full bg-white">
      <div className="max-w-spark mx-auto px-section-x py-section">
        <div className="text-center mb-12">
          <h2 className="text-spark-h1 text-spark-text-heading mb-4">Frequently Asked Questions</h2>
          <p className="text-spark-subheading text-spark-text-body">Everything you need to know about Spark AI</p>
        </div>
        
        <div className="max-w-3xl mx-auto space-y-4">
          {faqItems.map((item, index) => (
            <div 
              key={index} 
              className="rounded-lg border border-spark-purple/20 overflow-hidden bg-white shadow-sm"
            >
    <button
                className="w-full text-left px-6 py-4 flex justify-between items-center bg-white hover:bg-gray-50 transition-colors duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-spark-purple focus-visible:ring-opacity-50"
                onClick={() => setOpenItem(openItem === index ? null : index)}
                aria-expanded={openItem === index}
              >
                <span className="font-medium text-lg text-spark-text-heading">{item.question}</span>
                <ChevronDown 
                  className={`w-5 h-5 text-spark-purple transform transition-transform duration-200 flex-shrink-0 ${
                    openItem === index ? 'rotate-180' : ''
                  }`}
                />
              </button>
              
              <div 
                className={`transition-all duration-300 ease-in-out overflow-hidden ${
                  openItem === index 
                    ? 'max-h-[500px] opacity-100' 
                    : 'max-h-0 opacity-0'
                }`}
              >
                <div className={`px-6 pb-4 text-spark-text-body border-l-4 border-spark-purple bg-white transition-opacity duration-200 ${
                  openItem === index ? 'opacity-100' : 'opacity-0'
                }`}>
                  {item.answer}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center mt-12 pt-8 border-t border-gray-200">
          <p className="text-spark-text-body">
            Still have questions?{' '}
            <a 
              href="mailto:support@sparkai-dev.com"
              className="text-spark-purple hover:text-[#7B27CC] font-medium inline-flex items-center"
            >
              Contact our support team
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;