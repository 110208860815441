import React, { useState } from 'react';
import { Button } from '../components/ui/button';

const WaitlistForm = () => {
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: e.target.name.value,
      phone: e.target.phone.value,
      linkedin: e.target.linkedin.value
    };

    try {
      const response = await fetch('https://zsoctoqpc9.execute-api.us-east-1.amazonaws.com/prod/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setStatus('Success! Your request has been submitted.');
      } else {
        throw new Error('Submission failed');
      }
    } catch (error) {
      setStatus('There was an error. Please try again.');
    }
  };

  return (
    <div id="waitlist" className="w-full bg-white">
      <div className="max-w-spark mx-auto px-section-x py-section">
        <div className="max-w-md mx-auto">
          <h2 className="text-spark-h1 text-spark-text-heading mb-8 text-center">Join the Waitlist</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="text"
                name="name"
                placeholder="Your name"
                required
                className="w-full px-4 py-3 border border-gray-200 rounded-spark focus:ring-2 focus:ring-spark-purple focus:border-spark-purple bg-white text-spark-text-heading placeholder-gray-400"
              />
            </div>
            <div>
              <input
                type="tel"
                name="phone"
                placeholder="Your mobile number"
                required
                className="w-full px-4 py-3 border border-gray-200 rounded-spark focus:ring-2 focus:ring-spark-purple focus:border-spark-purple bg-white text-spark-text-heading placeholder-gray-400"
              />
            </div>
            <div>
              <input
                type="url"
                name="linkedin"
                placeholder="LinkedIn profile URL"
                className="w-full px-4 py-3 border border-gray-200 rounded-spark focus:ring-2 focus:ring-spark-purple focus:border-spark-purple bg-white text-spark-text-heading placeholder-gray-400"
              />
            </div>
            {status && (
              <div className={`text-sm ${status.includes('Success') ? 'text-green-600' : 'text-red-600'}`}>
                {status}
              </div>
            )}
            <Button 
              type="submit"
              className="w-full bg-spark-purple hover:bg-[#7B27CC] text-white px-4 py-3 text-[18px] rounded-spark shadow-spark"
            >
              Join the Waitlist
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WaitlistForm;