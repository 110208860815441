import React from 'react';
import Footer from '../components/Footer';

function PrivacyPolicy() {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header section */}
      <div className="bg-gray-50 py-8">
        <div className="px-8 md:px-16">
          <h1 className="text-5xl font-bold text-gray-900 mb-2">Privacy Policy for SparkAI</h1>
          <p className="text-gray-600">Last Updated: September 2024</p>
        </div>
      </div>

      {/* Content section */}
      <div className="px-8 md:px-16 py-12 flex-grow">
        <div>
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">1. Introduction</h2>
            <p className="text-gray-600 text-lg leading-relaxed mb-4">
              This Privacy Policy describes how SparkAI ("we," "us," "our") collects, uses, and protects your personal information. By using our services, you consent to the data practices described in this policy.
            </p>
            <p className="text-gray-600 text-lg">
              For questions, contact: <a href="mailto:support@sparkai-dev.com" className="text-blue-600 hover:text-blue-700">support@sparkai-dev.com</a>
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">2. Information We Collect</h2>
            <p className="text-gray-600 text-lg mb-4">We collect the following information:</p>
            <ul className="list-disc pl-6 text-gray-600 text-lg space-y-2">
              <li>Personal Data: Your name, email address, and mobile number.</li>
              <li>Conversation Data: Recordings of AI interviews.</li>
              <li>Usage Activity: Login information and interaction with our platform.</li>
              <li>Engagement Metrics: Performance data of social media posts generated by SparkAI.</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">3. How We Use Your Data</h2>
            <p className="text-gray-600 text-lg mb-4">The data we collect is used to:</p>
            <ul className="list-disc pl-6 text-gray-600 text-lg space-y-2">
              <li>Provide and improve our services.</li>
              <li>Generate social media content tailored to your professional experiences.</li>
              <li>Enhance our AI systems and improve future content generation.</li>
              <li>Analyze usage patterns and engagement to improve the user experience.</li>
            </ul>
          </section>

          {/* Continue with sections 4-10 following the same pattern */}
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-100 py-5 mt-10">
        <div className="px-8 md:px-16 text-center">
          <p className="text-spark-text-body">
            <a href="terms-of-service.html" className="text-spark-purple hover:text-[#7B27CC] mr-4">
              Terms of Service
            </a>
            <a href="privacy-policy.html" className="text-spark-purple hover:text-[#7B27CC]">
              Privacy Policy
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;
