import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '../components/ui/card';
import { Phone, Brain, Share2, Bell, ThumbsUp, BarChart, ArrowRight } from 'lucide-react';

const HowItWorks = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(false);

  const steps = [
    {
      icon: <Phone className="w-8 h-8" />,
      title: "AI Interview",
      description: "Have a 15-minute phone conversation with our AI interviewer that adapts to your expertise and style",
      detail: "No preparation needed - just talk about your experiences and insights",
      color: "bg-purple-100"
    },
    {
      icon: <Brain className="w-8 h-8" />,
      title: "AI Processing",
      description: "Our AI analyzes the conversation, extracting key insights and understanding your voice",
      detail: "Advanced language models ensure your content remains authentic to you",
      color: "bg-purple-100"
    },
    {
      icon: <Share2 className="w-8 h-8" />,
      title: "Content Creation",
      description: "AI generates a month's worth of engaging posts across multiple formats and platforms",
      detail: "From short posts to long-form content, all optimized for each platform",
      color: "bg-purple-100"
    },
    {
      icon: <Bell className="w-8 h-8" />,
      title: "Review Alert",
      description: "Receive a text message when your content is ready for review",
      detail: "Quick and easy approval process from your phone",
      color: "bg-purple-100"
    },
    {
      icon: <ThumbsUp className="w-8 h-8" />,
      title: "Smart Publishing",
      description: "Approved content is automatically scheduled for optimal engagement times",
      detail: "AI determines the best posting schedule based on your audience",
      color: "bg-purple-100"
    },
    {
      icon: <BarChart className="w-8 h-8" />,
      title: "Performance Tracking",
      description: "Monitor engagement and reach with detailed analytics",
      detail: "AI continuously learns and improves your content strategy",
      color: "bg-purple-100"
    }
  ];

  useEffect(() => {
    if (isAutoPlaying) {
      const timer = setInterval(() => {
        setActiveStep((prev) => (prev === steps.length - 1 ? 0 : prev + 1));
      }, 3000);
      return () => clearInterval(timer);
    }
  }, [isAutoPlaying, steps.length]);

  return (
    <div className="w-full bg-white">
      <div className="max-w-spark mx-auto px-section-x py-section">
        <div className="text-center mb-12">
          <h2 className="text-spark-h1 text-spark-text-heading mb-4">How It Works</h2>
          <p className="text-spark-subheading text-spark-text-body mb-4">From conversation to content in six simple steps</p>
          <button
            onClick={() => setIsAutoPlaying(!isAutoPlaying)}
            className="text-spark-purple hover:text-[#7B27CC] font-medium inline-flex items-center bg-white px-4 py-2 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors duration-200"
          >
            {isAutoPlaying ? "Stop Auto-Play" : "Watch Process"}
          </button>
        </div>

        <div className="mb-12 max-w-2xl mx-auto">
          <div className="h-2 bg-gray-200 rounded-full">
            <div 
              className="h-2 bg-spark-purple rounded-full transition-all duration-500"
              style={{ width: `${((activeStep + 1) / steps.length) * 100}%` }}
            />
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 relative">
          {steps.map((step, index) => (
            <div key={index} className="relative">
              <Card 
                className={`cursor-pointer transition-all duration-500 bg-white ${
                  activeStep === index ? 'scale-105 shadow-spark translate-y-[-8px]' : 
                  activeStep > index ? 'opacity-50' : ''
                }`}
                onMouseEnter={() => {
                  setIsAutoPlaying(false);
                  setActiveStep(index);
                }}
              >
                <CardContent className="p-6 space-y-4">
                  <div className={`w-16 h-16 rounded-full flex items-center justify-center transition-colors duration-500 ${
                    activeStep === index ? 'bg-[rgba(138,43,226,0.1)]' : 'bg-gray-100'
                  }`}>
                    {step.icon}
                  </div>
                  <h3 className="text-xl font-semibold flex items-center gap-2 text-spark-text-heading">
                    <span className="w-8 h-8 rounded-full bg-spark-purple text-white flex items-center justify-center text-sm">
                      {index + 1}
                    </span>
                    {step.title}
                  </h3>
                  <p className="text-spark-text-body">{step.description}</p>
                  <p className={`text-sm text-spark-purple transition-all duration-500 ${
                    activeStep === index ? 'opacity-100 max-h-20' : 'opacity-0 max-h-0'
                  } overflow-hidden`}>
                    {step.detail}
                  </p>
                </CardContent>
              </Card>
              
              {index < steps.length - 1 && (index + 1) % 3 !== 0 && (
                <div className="hidden lg:block absolute top-1/2 -right-4 transform -translate-y-1/2">
                  <ArrowRight className={`w-8 h-8 transition-colors duration-500 ${
                    activeStep > index ? 'text-spark-purple' : 'text-gray-300'
                  }`} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;