import React from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet';
import { ArrowLeft } from 'lucide-react';

const ResourceDetailPage = () => {
  const { slug } = useParams();
  const [article, setArticle] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchArticle = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const baseUrl = window.location.origin;
        const requestUrl = `${baseUrl}/resources/${slug}.json`;
        
        const response = await fetch(requestUrl);
        if (!response.ok) {
          throw new Error(`Article not found (Status: ${response.status})`);
        }
        
        const data = await response.json();
        setArticle(data);
      } catch (error) {
        console.error('Error fetching article:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (slug) {
      fetchArticle();
    }
  }, [slug]);

  const components = {
    h1: ({ node, ...props }) => <h1 className="text-4xl font-bold mt-8 mb-4 text-gray-900" {...props} />,
    h2: ({ node, ...props }) => <h2 className="text-3xl font-bold mt-8 mb-4 text-gray-900" {...props} />,
    h3: ({ node, ...props }) => <h3 className="text-2xl font-bold mt-6 mb-3 text-gray-900" {...props} />,
    p: ({ node, ...props }) => <p className="mb-4 leading-relaxed text-gray-900" {...props} />,
    ul: ({ node, ...props }) => <ul className="list-disc list-inside mb-4 ml-4 text-gray-900" {...props} />,
    ol: ({ node, ...props }) => <ol className="list-decimal list-inside mb-4 ml-4 text-gray-900" {...props} />,
    li: ({ node, ...props }) => <li className="mb-2 text-gray-900" {...props} />,
    a: ({ node, ...props }) => (
      <a 
        className="text-blue-600 hover:text-blue-800 underline" 
        target="_blank"
        rel="noopener noreferrer"
        {...props} 
      />
    ),
    blockquote: ({ node, ...props }) => (
      <blockquote 
        className="border-l-4 border-gray-200 pl-4 my-4 italic text-gray-900"
        {...props} 
      />
    ),
  };

  if (isLoading) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-gray-200 rounded w-full"></div>
            <div className="h-4 bg-gray-200 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 rounded-lg p-6">
          <h2 className="text-red-800 text-lg font-semibold mb-2">Unable to Load Article</h2>
          <p className="text-red-600 mb-4">{error}</p>
          <Link 
            to="/resources" 
            className="inline-flex items-center text-red-700 hover:text-red-900"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Return to Resources
          </Link>
        </div>
      </div>
    );
  }

  if (!article) return null;

  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>{article.title} | Resources</title>
        <meta name="description" content={article.description} />
      </Helmet>

      <article className="max-w-4xl mx-auto px-4 py-8">
        <Link
          to="/resources"
          className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Resources
        </Link>

        <div className="prose prose-lg max-w-none text-gray-900">
          <ReactMarkdown components={components}>
            {article.content}
          </ReactMarkdown>
        </div>
      </article>
    </div>
  );
};

export default ResourceDetailPage;