import { useState } from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';

const HomeFAQ = () => {
  const [openFAQs, setOpenFAQs] = useState(new Set());

  const faqData = [
    {
      question: "Why use Spark AI instead of just using ChatGPT directly?",
      answer: "While tools like ChatGPT are powerful for generating content, Spark AI takes things a step further by automating the entire process. Instead of manually prompting ChatGPT for ideas, Spark AI conducts a short interview with you to extract your thoughts and insights. Then, it automatically generates, schedules, and posts the content on your behalf—while also tracking its performance and making improvements over time. Spark AI handles the full content lifecycle, allowing you to focus on your expertise rather than managing the details of content creation and distribution."
    },
    {
      question: "Why not just keep doing social media manually?",
      answer: "Managing social media manually is time-consuming and requires consistent effort to keep your audience engaged. Spark AI is designed to save you time by automating the process of content creation, posting, and performance tracking. In just 15 minutes, you can generate a month's worth of high-quality posts that are shared automatically, letting you stay active online without spending hours each week writing and scheduling content. Plus, Spark AI uses data and insights to improve over time, ensuring your posts become more effective with minimal effort on your part."
    },
    {
      question: "How can I trust the quality of Spark AI posts?",
      answer: "Spark AI is built to reflect your unique voice and expertise. During your 15-minute session, the AI intelligently captures your insights and transforms them into posts that align with your messaging. Before anything goes live, you have full control to review and approve posts to ensure they meet your standards. Over time, Spark AI learns what works best for your audience by analyzing engagement data, allowing it to refine its output and increase the effectiveness of future posts."
    }
  ];

  const toggleFAQ = (index) => {
    setOpenFAQs(prev => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="divide-y divide-gray-200">
        {faqData.map((faq, index) => (
          <div key={index} className="py-6">
            <button
              onClick={() => toggleFAQ(index)}
              className="flex justify-between items-start w-full text-left bg-white rounded-lg"
            >
              <span className="text-lg font-medium text-spark-text-heading">
                {faq.question}
              </span>
              <span className="ml-6 flex-shrink-0">
                {openFAQs.has(index) ? (
                  <MinusIcon className="h-6 w-6 text-spark-purple bg-white" />
                ) : (
                  <PlusIcon className="h-6 w-6 text-spark-purple bg-white" />
                )}
              </span>
            </button>
            {openFAQs.has(index) && (
              <div className="mt-2 text-base text-spark-text-body bg-white">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeFAQ;
