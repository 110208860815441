// src/pages/ResourcesPage.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ResourcesPage = () => {
  const [articles, setArticles] = React.useState([]);

  React.useEffect(() => {
    const fetchArticles = async () => {
      try {
        // In production, you might want to use an API endpoint that returns all articles
        const articleFiles = [
          'become-thought-leader',
          'marketing-thought-leaders',
          'personal-branding-strategy',
          'thought-leadership-conferences',
          'thought-leadership-content'
        ];

        const articlesData = await Promise.all(
          articleFiles.map(async (file) => {
            const response = await fetch(`/resources/${file}.json`);
            const data = await response.json();
            return { ...data, slug: file };
          })
        );

        setArticles(articlesData);
      } catch (error) {
        console.error('Error loading articles:', error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <Helmet>
        <title>Resources - Thought Leadership Insights</title>
        <meta 
          name="description" 
          content="Explore our comprehensive resources on thought leadership, personal branding, and content strategy." 
        />
      </Helmet>

      <h1 className="text-3xl font-bold mb-8">Resources</h1>
      
      <div className="grid gap-6">
        {articles.map((article) => (
          <article key={article.slug} className="border rounded-lg p-6 hover:shadow-lg transition-shadow">
            <Link to={`/resources/${article.slug}`} className="block">
              <h2 className="text-xl font-semibold mb-2">{article.title}</h2>
              <p className="text-gray-600">{article.description}</p>
              <div className="mt-4">
                <span className="text-blue-600">Read more →</span>
              </div>
            </Link>
          </article>
        ))}
      </div>
    </div>
  );
};

export default ResourcesPage;