import React, { useState } from 'react';

const HomeWaitlistForm = ({ id, onSubmit }) => {
  const [status, setStatus] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    linkedin: ''
  });

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^(\(\d{3}\)\s*|\d{3}[-\s]?)?\d{3}[-\s]?\d{4}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validatePhoneNumber(formData.phone)) {
      setStatus('Please enter a valid phone number (e.g., (650) 123-1234 or 650-123-1234).');
      return;
    }

    try {
      const result = await onSubmit(id, formData);
      setStatus(result);
      if (result.includes('Success')) {
        setFormData({ name: '', phone: '', linkedin: '' }); // Clear form on success
      }
    } catch (error) {
      setStatus('There was an error. Please try again.');
    }
  };

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-md w-full mx-auto mt-8">
      <div className="space-y-4">
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Your name"
          required
          className="w-full px-4 py-3 bg-transparent border border-gray-300 rounded-spark focus:ring-2 focus:ring-spark-purple focus:border-spark-purple text-spark-text-heading placeholder-gray-400"
        />
        
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Your mobile number"
          required
          className="w-full px-4 py-3 bg-transparent border border-gray-300 rounded-spark focus:ring-2 focus:ring-spark-purple focus:border-spark-purple text-spark-text-heading placeholder-gray-400"
        />
        
        <input
          type="url"
          name="linkedin"
          value={formData.linkedin}
          onChange={handleChange}
          placeholder="LinkedIn profile URL"
          className="w-full px-4 py-3 bg-transparent border border-gray-300 rounded-spark focus:ring-2 focus:ring-spark-purple focus:border-spark-purple text-spark-text-heading placeholder-gray-400"
        />

        {status && (
          <div className={`text-sm ${status.includes('Success') ? 'text-green-600' : 'text-red-600'}`}>
            {status}
          </div>
        )}

        <button 
          type="submit"
          className="w-full bg-spark-purple hover:bg-spark-purple/90 text-white px-4 py-3 text-lg rounded-spark transition-colors duration-200"
        >
          Join the Waitlist
        </button>
      </div>
    </form>
  );
};

export default HomeWaitlistForm;