import React from 'react';
import { Card, CardContent } from '../components/ui/card';
import { Check } from 'lucide-react';
import { Button } from '../components/ui/button';

const PricingSection = () => {
  const scrollToWaitlist = () => {
    document.getElementById('waitlist').scrollIntoView({ behavior: 'smooth' });
  };

  const plans = [
    {
      name: "Starter",
      description: "Perfect for individuals starting their thought leadership journey",
      price: "Coming Soon",
      features: [
        "Weekly AI interviews",
        "LinkedIn posting",
        "Basic analytics",
        "Standard post review time"
      ]
    },
    {
      name: "Professional",
      description: "For active thought leaders who want to maximize their impact",
      price: "Coming Soon",
      isPopular: true,
      features: [
        "More frequent AI interviews",
        "Multiple social platforms",
        "Advanced analytics",
        "Priority post review",
        "Custom posting schedule",
        "Personal branding settings"
      ]
    },
    {
      name: "Enterprise",
      description: "For teams and organizations",
      price: "Contact Us",
      features: [
        "Multiple team member accounts",
        "Brand voice consistency tools",
        "Collaboration features",
        "Dedicated support",
        "Custom integration options",
        "Advanced security features"
      ]
    }
  ];

  return (
    <div className="w-full bg-white">
      <div className="max-w-spark mx-auto px-section-x py-section">
        <div className="text-center mb-12">
          <h2 className="text-spark-h1 text-spark-text-heading mb-4">Simple, Transparent Pricing</h2>
          <p className="text-spark-subheading text-spark-text-body">Choose the plan that's right for you</p>
        </div>
        
        <div className="grid md:grid-cols-3 gap-8 max-w-7xl mx-auto">
          {plans.map((plan, index) => (
            <Card 
              key={index} 
              className={`relative bg-white transform transition-all duration-300 hover:scale-105 ${
                plan.isPopular ? 'ring-2 ring-spark-purple shadow-spark' : 'shadow-sm hover:shadow-spark'
              }`}
            >
              <CardContent className="p-8">
                {plan.isPopular && (
                  <span className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-spark-purple text-white px-4 py-1 rounded-full text-sm font-medium">
                    Most Popular
                  </span>
                )}
                
                <div className="mb-8">
                  <h3 className="text-2xl font-bold mb-2 text-spark-text-heading">{plan.name}</h3>
                  <p className="text-spark-text-body">{plan.description}</p>
                </div>

                <div className="mb-8">
                  <div className="text-4xl font-bold text-spark-text-heading">{plan.price}</div>
                </div>
                
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start">
                      <Check className="w-5 h-5 text-spark-purple mr-3 mt-0.5 flex-shrink-0" />
                      <span className="text-spark-text-body">{feature}</span>
                    </li>
                  ))}
                </ul>
                
                <Button 
                  className={`w-full py-6 text-base font-medium ${
                    plan.isPopular 
                      ? 'bg-spark-purple text-white hover:bg-[#7B27CC]' 
                      : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                  }`}
                  onClick={scrollToWaitlist}
                >
                  Join Waitlist
                </Button>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingSection;